import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import TableContainer from '../../../../UI/Dashboard/TableContainer'
import OfficeAddress from './officeAddress'
import RandomId from '../../../../../helpers/RandomId'

export default function Setting() {
    const [infoDetail,setInfoDetail] = useState(null)
    const [offices,setOffices] = useState([])
    const urlParams = useParams()
    const navigate = useNavigate()
    const dataIcon = useRef()

    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/setting/getSetting`,
                token: true
              })
              
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
                const offices = response.data.result[0]?.offices ?? []
                if (offices && offices.length) {
                  setOffices(offices)
                }
              }
            })()
          },100)
          return()=>{
            clearInterval(getResponse)
          }
    },[])


    const updateSetting = async (values) => {
        const filterOfficesData = []
        let isError = false
        if(offices && offices.length){
            offices.some((item) => {
                if(!item.latitude || !item.longitude || isNaN(item.latitude) || isNaN(item.longitude)){
                    toast.error('All office address details are required');
                    isError = true;
                    return true;
                }else if (!item.name.trim() || !item.location.trim() || !item.contactNo.trim()) {
                    toast.error('All office address details are required');
                    isError = true;
                    return true;
                } else {
                    // delete item.key;
                    item.longitude = parseFloat(item.longitude);
                    item.latitude = parseFloat(item.latitude);
                    filterOfficesData.push(item);
                }
            })
        }
        if(!isError){
        const data = {
            email: values.email,
            telephone: values.telephone,
            footer_note: values.footer_note,
            facebook: values.facebook,
            linkedin: values.linkedin,
            instagram: values.instagram,
            youtube: values.youtube,
            whatsapp: values.whatsapp,
            copywrite: values.copywrite,
            leadGenText: values.leadGenText,
            store_name: 'HouseEazy',
            google_iframe:values.google_iframe,
            address:values.address,
            offices:filterOfficesData
        }
        const addMeta = await Ajax({
            url: `/setting/637e1d00e020842ca4df7bfd`,
            method: 'PUT',
            token: true,
            data: data,
            loader: true
        })
        if(addMeta.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(addMeta.data.status === "SUCCESS!"){
            toast.success('Setting Updated Successfully')
            setTimeout(function(){
                navigate('/admin/setting/general')
            },500)
        }
    }
    }

    const generateRandomId = (number) =>{
        let randomId = RandomId(number)
        const randomKey = []
        offices.map((item)=>randomKey.push(item.id))
        if(randomKey.includes(randomId)){
            generateRandomId(20)
        }else{
            return randomId
        }
    }

    const addOfficeAddress = () => {
        let uniqueId = generateRandomId(20)
        const data = {
          id: uniqueId
        }
        setOffices([...offices, data])
    }
    return (
        <ContentLayout title="General Setting">
            <Helmet>
                <title>General Setting</title>
            </Helmet>
            <DataView title="Infomation">
            {infoDetail === null ? <DataLoader/> :
                <Formik
                    initialValues={{ 
                        email: infoDetail.email ? infoDetail.email : '',
                        telephone: infoDetail.telephone ? infoDetail.telephone : '',
                        footer_note: infoDetail.footer_note ? infoDetail.footer_note : '',
                        facebook: infoDetail.facebook ? infoDetail.facebook : '',
                        linkedin: infoDetail.linkedin ? infoDetail.linkedin : '',
                        instagram: infoDetail.instagram ? infoDetail.instagram : '',
                        youtube: infoDetail.youtube ? infoDetail.youtube : '',
                        whatsapp: infoDetail.whatsapp ? infoDetail.whatsapp : '',
                        copywrite: infoDetail.copywrite ? infoDetail.copywrite : '',
                        leadGenText: infoDetail.leadGenText ? infoDetail.leadGenText : '',
                        google_iframe: infoDetail.google_iframe ? infoDetail.google_iframe : '',
                        address: infoDetail.address ? infoDetail.address : ''
                    }}
                    validationSchema={Yup.object({
                        email: Yup.string()
                        .email('Invalid email address')
                        .required('Field is Required'),
                        telephone: Yup.string()
                        .required('Field is Required'),
                        footer_note: Yup.string()
                        .required('Field is Required'),
                        facebook: Yup.string()
                        .required('Field is Required'),
                        linkedin: Yup.string()
                        .required('Field is Required'),
                        instagram: Yup.string()
                        .required('Field is Required'),
                        youtube: Yup.string()
                        .required('Field is Required'),
                        whatsapp: Yup.string()
                        .required('Field is Required'),
                        copywrite: Yup.string()
                        .required('Field is Required'),
                        leadGenText: Yup.string()
                        .required('Field is Required'),
                        google_iframe: Yup.string()
                        .required('Field is Required'),
                         address: Yup.string()
                        .required('Field is Required'),
                    })}
                    onSubmit={(values) => {
                        updateSetting(values)
                    }}
                >
                <Form>
                <ul className="nav nav-tabs">
                <li className="nav-item"><a href="#tab-info" data-bs-toggle="tab" className="nav-link active">Basic Details</a></li>
                <li className="nav-item"><a href="#tab-offices" data-bs-toggle="tab" className="nav-link">Offices</a></li>
              </ul>
                 <div className="tab-content">
                    <div className="tab-pane active" id="tab-info">
                        <div className='row'>
                        <div className='col-md-4'>
                            <FormGroup label="Phone" required>
                                <Field placeholder="Phone" name="telephone" className='form-control' />
                                <ErrorField><ErrorMessage name="telephone"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Email" required>
                                <Field placeholder="Email" name="email" className='form-control' />
                                <ErrorField><ErrorMessage name="email"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Whatsapp" required>
                                <Field placeholder="Whatsapp" name="whatsapp" className='form-control' />
                                <ErrorField><ErrorMessage name="whatsapp"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup label="Footer About Us" required>
                                <Field as="textarea" placeholder="Footer About Us" name="footer_note" className='form-control' />
                                <ErrorField><ErrorMessage name="footer_note"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Facebook" required>
                                <Field placeholder="Facebook" name="facebook" className='form-control' />
                                <ErrorField><ErrorMessage name="facebook"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Linkedin" required>
                                <Field placeholder="Linkedin" name="linkedin" className='form-control' />
                                <ErrorField><ErrorMessage name="linkedin"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Instagram" required>
                                <Field placeholder="Instagram" name="instagram" className='form-control' />
                                <ErrorField><ErrorMessage name="instagram"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Youtube" required>
                                <Field placeholder="Youtube" name="youtube" className='form-control' />
                                <ErrorField><ErrorMessage name="youtube"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Copyright" required>
                                <Field placeholder="Copyright" name="copywrite" className='form-control' />
                                <ErrorField><ErrorMessage name="copywrite"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Lead Gen Heading" required>
                                <Field placeholder="Lead Gen Heading" name="leadGenText" className='form-control' />
                                <ErrorField><ErrorMessage name="leadGenText"/></ErrorField>
                            </FormGroup>
                        </div>

                        <div className='col-md-6'>
                            <FormGroup label="Google Iframe" required>
                                <Field placeholder="Google Iframe" name="google_iframe" className='form-control' />
                                <ErrorField><ErrorMessage name="google_iframe"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup label="Address" required>
                                <Field placeholder="Address" name="address" className='form-control' />
                                <ErrorField><ErrorMessage name="address"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                    </div>
                    <div className="tab-pane active" id="tab-offices">
                    <div className="col-md-12">
                  <TableContainer>
                    <thead>
                      <tr>
                        <td className="text-start">Name</td>
                        <td className="text-start">Location</td>
                        <td className='text-start'>Latitude</td>
                        <td className='text-start'>Longitude</td>
                        <td className='text-start'>Contact</td>
                        <td className='text-start'>Thumbnail</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {offices.length > 0 && offices.map((data, index) => {
                        return (
                          <>
                            <OfficeAddress key={data.id} data={data} target={data.id} action={setOffices}/>
                          </>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="6"></td>
                        <td className="text-end">
                          <button type="button" className="btn btn-primary" onClick={addOfficeAddress}>
                            <i className="fas fa-plus-circle" ></i>
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  </TableContainer>
                    </div>
                    </div>
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik>}
            </DataView> 
        </ContentLayout>
    )
}
