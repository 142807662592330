import React, { useState, useEffect } from 'react'
import axios from "axios"

export default function OfficeAddress({target, action,value,data}) {
    const [url,setUrl] = useState(data.thumbnail)
    useEffect(()=>{
        (async () => {
            action(current =>{
                current.map((item)=>{
                    if(item.id == target){
                        item.id = target
                        item.name = data.name || ""
                        item.location = data.location || ""
                        item.latitude = data.latitude || 0
                        item.longitude = data.longitude || 0
                        item.contactNo = data.contactNo || ""                        
                        item.thumbnail = url || ""
                    }
                    return item
                })  
            return current
            })
          })()
    })
    const deleteRow = () => {
        let deleteConfirm = window.confirm("Are you sure want to delete ?")
        if(deleteConfirm === true) {
            action(current =>
                current.filter(data => {
                    return data.id !== target;
                })
            )
        }
    }
    const upload = async (e) => {
        document.querySelector(".form-loader").classList.add("show")
        const file = e.target.files[0]
        const formData = new FormData();
        formData.append("file", file);
        const URL = `${process.env.REACT_APP_JOURNEY_URL}/images`;
        const reqHeaders = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const response = await axios.post(URL, formData, reqHeaders);
        const uploadDocRes = response.data;
        if(uploadDocRes && uploadDocRes.length >0){
           setUrl(uploadDocRes[0])
        }
        document.querySelector(".form-loader").classList.remove("show")
    }
   
    return (
        <tr>
            <td className="text-start">
                <input className="form-control" defaultValue={data.name} onChange={(e)=>data.name = e.target.value} name="name"/>  
            </td>
            <td className="text-start">
               <input className="form-control" defaultValue={data.location} onChange={(e)=>data.location = e.target.value} name="location" />
            </td>
            <td>
                <input className="form-control" name="longitude" onChange={(e)=>data.longitude = e.target.value} defaultValue={data.longitude} /> 
            </td>
            <td>
                <input className="form-control" name="latitude"  onChange={(e)=>data.latitude = e.target.value} defaultValue={data.latitude} /> 
            </td>
            <td >
                <input className="form-control" name="contactNumber" onChange={(e)=>data.contactNo = e.target.value} defaultValue={data.contactNo}/>   
            </td>
            <td>
            <div className="input-group">
                <input type="file" onChange={upload} name="thumbnail" className="form-control" />
            </div> 
             {url && 
                <div>
                    <img src={url} width={80} height={50}/>
                </div>
                }
            </td>  
            <td className="text-end">
                <button type="button" onClick={deleteRow} className="btn btn-danger">
                    <i className="fas fa-minus-circle"></i>
                </button>
            </td>
        </tr>
    )
}